<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <v-card id="widget_move" class="pa-4" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <!-- <v-card-title
      class="mb-1 ft-700"
      v-html="$vuetify.lang.t('$vuetify.widget_move.title')"></v-card-title> -->
    <v-card-text class="pa-md-8 pa-sm-2" style="color: #3d3d3d; font-size: 1rem">
      <p>
        Es ist uns technisch aktuell leider noch nicht möglich, einen bereits bestehenden Vertrag
        auf eine andere Verbrauchsstelle zu schalten. Wir melden deine aktuelle Abnahmestelle zum
        Umzugstermin von der Belieferung ab und bitten dich, einen neuen Vertragsabschluss
        durchzuführen, um an der neuen Adresse beliefert werden zu können.
      </p>
      <p>
        Bitte nutze unseren Tarifrechner auf dieser Seite, um zu prüfen, ob wir dich an deiner neuen
        Adresse beliefern können.
        <br />
        Den direkten Link hierzu findest du hier:
        <a href="https://www.veganstrom.com/hausstrom/">Zum Tarifrechner</a>.
      </p>
      <p>
        Mit dem Gutscheincode <b>Sonne</b> erhältst du 30,-€ als Gutschrift auf deine erste Rechnung
        bei Abschluss eines Clever Tarifs.
      </p>
      <p>
        Bei Rückfragen steht dir unser Kundenservice unter
        <a href="mailto:service@veganstrom.com">service@veganstrom.com</a> oder unter
        <a href="tel:+4930549073">+4930 549 073 272</a>
        gern zur Verfügung.
      </p>
    </v-card-text>
    <v-card-text v-if="false">
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Row 1 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" :class="$vuetify.breakpoint.mobile ? '' : 'pr-3'">
            <v-text-field
              v-model="firstName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.first_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="lastName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.last_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 2 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" :class="$vuetify.breakpoint.mobile ? '' : 'pr-3'">
            <v-text-field
              v-model="street"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.street')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="house_number"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.house_number')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 3 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" :class="$vuetify.breakpoint.mobile ? '' : 'pr-3'">
            <v-text-field
              v-model="zip_code"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.zip_code')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="city"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.city')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 4 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" :class="$vuetify.breakpoint.mobile ? '' : 'pr-3'">
            <v-text-field
              v-model="move_out_date"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.move_out_date')"
              required
              outlined
              color="blue"
              background-color="text_area_background"
              type="date"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="move_in_date"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.move_in_date')"
              required
              outlined
              color="blue"
              background-color="text_area_background"
              type="date"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 5 -->
        <v-row dense>
          <v-col cols="12" md="12" lg="8">
            <v-text-field
              v-model="meter_number"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_move.meter_number')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Selection contact -->
        <v-row dense>
          <v-col cols="12" lg="8">
            <p>
              {{ $vuetify.lang.t('$vuetify.widget_move.contact_selection') }}
            </p>
            <v-radio-group v-model="radioGroup">
              <v-radio v-for="n in 2" :key="n" :value="n" color="accent">
                <!-- Option email -->
                <template #label>
                  <v-text-field
                    v-if="n == 1"
                    v-model="email"
                    :rules="radioGroup == 1 ? emailRules : []"
                    :label="$vuetify.lang.t('$vuetify.widget_move.email')"
                    :required="radioGroup == 1 ? true : false"
                    outlined
                    color="blue"
                    background-color="text_area_background"></v-text-field>
                  <!-- Option phone -->
                  <v-text-field
                    v-if="n == 2"
                    v-model="phone"
                    :rules="radioGroup == 2 ? fieldRules : []"
                    :label="$vuetify.lang.t('$vuetify.widget_move.phone')"
                    :required="radioGroup == 2 ? true : false"
                    outlined
                    color="blue"
                    background-color="text_area_background"></v-text-field>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <!-- Send button -->
        <v-row>
          <v-col cols="12" md="8" lg="6">
            <v-btn
              height="45"
              :disabled="!valid"
              color="accent"
              class="mr-4 mb-1 text-none ft-700"
              @click="validate">
              {{ $vuetify.lang.t('$vuetify.widget_move.button_text_send') }}
            </v-btn>

            <!-- <v-btn
              height="45"
              color="primary"
              class="mr-4 mb-1 text-none ft-700"
              @click="reset"
            >
              {{ $vuetify.lang.t("$vuetify.widget_move.button_text_reset") }}
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
      <!-- Alert -->
      <v-row>
        <v-col cols="12" md="8" lg="6">
          <v-alert
            v-show="showAlert"
            class="mt-2"
            :color="alertType == 'error' ? 'error' : 'accent'"
            dense
            :type="alertType">
            {{ alertMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { postMove } from '../../api/api'
import { mapState } from 'vuex'
import { emailRegex } from '../../api/util'

export default {
  data() {
    return {
      valid: true,
      firstName: '',
      lastName: '',
      street: '',
      house_number: '',
      zip_code: '',
      city: '',
      move_out_date: '',
      move_in_date: '',
      meter_number: '',
      email: '',
      phone: '',
      subject: '',
      fieldRules: [v => !!v || this.$vuetify.lang.t('$vuetify.widget_move.text_required')],
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_move.text_required'),
        v => !v || emailRegex.test(v) || this.$vuetify.lang.t('$vuetify.widget_move.syntax'),
      ],
      radioGroup: 1,
      showAlert: false,
      alertType: 'success',
      alertMessage: this.$vuetify.lang.t('$vuetify.widget_move.alert_success'),
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
    }),
  },
  mounted() {
    // Set initial data
    this.firstName = this.customer_selected.first_name
    this.lastName = this.customer_selected.last_name
    this.email = this.customer_selected.email
    this.phone = this.customer_selected.phone
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        // Create payload
        let payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          street: this.street,
          house_number: this.house_number,
          zip_code: this.zip_code,
          city: this.city,
          move_out_date: this.move_out_date,
          move_in_date: this.move_in_date,
          meter_number: this.meter_number,
          customer_number: this.customer_selected.customer_number,
          contract_number: this.contract_selected.contract_number,
          contract_id: this.contract_selected.id,
          subject: this.customer_selected.customer_number,
        }
        switch (this.radioGroup) {
          case 1:
            payload.email = this.email
            break
          case 2:
            payload.phone = this.phone
            break
          default:
            break
        }
        // Call API
        postMove(payload).then(res => {
          // Set corresponding Alert
          if (res) {
            this.showAlert = true
            this.alertType = 'success'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_move.alert_success'
            )
          } else {
            this.showAlert = true
            this.alertType = 'error'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_move.alert_error'
            )
          }
        })
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style>
#widget_move .hidden-sm-and-down .v-icon {
  color: white !important;
}
#widget_move .v-input--selection-controls__input {
  margin-top: -26px !important;
}
</style>
