export function computeHighNumberHint(
  meter_reading,
  last_meter_reading,
  last_meter_reading_date,
  high_consumption_factor,
  annual_consumption,
  meter_reading_date
) {
  if (
    !meter_reading ||
    isNaN(Number(meter_reading)) ||
    !last_meter_reading ||
    !last_meter_reading_date ||
    !annual_consumption
  ) {
    return ''
  }
  const lastDateSplit = last_meter_reading_date.split('.')
  const lastDate = new Date(`${lastDateSplit[1]}/${lastDateSplit[0]}/${lastDateSplit[2]}`)
  const daysDiff = Math.floor((new Date(meter_reading_date) - lastDate) / (1000 * 60 * 60 * 24))
  const valueDiff = Number(meter_reading) - Number(last_meter_reading)
  const factor = ((valueDiff / daysDiff) * 365) / annual_consumption
  if (factor > high_consumption_factor) {
    return 'Dein Verbrauch ist deutlich höher als erwartet. Bitte überprüfe deine Eingabe. Wenn du dir sicher bist, dass der Wert stimmt, kannst du ihn trotzdem übermitteln.' // TODO
  }
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
